import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import EcosystemMap from "../../components/ecosystem-map"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import MobileEcosystems from '../../components/ecosystems-list'
import EcosystemTable from '../../components/ecosystem-table'
import ScrollArrow from "../../components/scroll-arrow"


export default class EstoniaEcosystem extends React.Component {
  constructor(props){
      super(props);

      this.state = {
          showEcosystemsLink: false,
          showNextLink: false,
          showPrevLink: false
      }

      this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event){
      // Esc key press
      if(event.keyCode === 27) {
          this.setState({
              showEcosystemsLink: true
          });

          setTimeout(() => {
              let linkRef = document.getElementById("esc-action");
              linkRef.click();
          }, 50)
      }

      // Left Arrow key press
      if(event.keyCode === 37) {
          this.setState({
              showPrevLink: true
          });

          setTimeout(() => {
              let prevLinkRef = document.getElementById("prev-action");
              prevLinkRef.click();
          }, 50)
      }

      // Right Arrow key press
      if(event.keyCode === 39) {
          this.setState({
              showNextLink: true
          });

          setTimeout(() => {
              let nextLinkRef = document.getElementById("next-action");
              nextLinkRef.click();
          }, 50)
      }
  }
  componentDidMount(){
      document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount(){
      document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {

      return (
  <Layout>
            {
            this.state.showEcosystemsLink &&
            <AniLink id="esc-action" fade to="/" duration={0.4}></AniLink >
        }
        {
            this.state.showPrevLink &&
            <AniLink id="prev-action" fade to="/ecosystems/lithuania" duration={0.4}></AniLink >
        }
        {
            this.state.showNextLink &&
            <AniLink id="next-action" fade to="/ecosystems/estonia" duration={0.4}></AniLink >
        }
    <SEO title="Compare Startup Ecosystems" description="Look in-depth at startup ecosystems in different countries, and understand the benefits and disadvantages of building a company there."/>


    <div id="contains-map">
      <EcosystemMap />

      <div id="arrow-container">    
      <ScrollArrow />
   </div>
    </div>
    <div id="visa-information-container">
        <div id="inner-information-container">
    <h1>Startup Ecosystems</h1>
    <p className="some-description">
      Knowing where to base your startup for the purposes of incorporation and taxation is an essential part of your entrepreneurial journey. Here are some of the ecosystems that we have surveyed and found to have progressive policy.</p>
    {/* <h2>Startup Ecosystems List</h2> */}
    <EcosystemTable/>
    <MobileEcosystems/>
    </div>
    </div>
  </Layout>
)
      }
    }


