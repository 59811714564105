import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
 
const EcosystemTable = ({ children }) => {


    return (

        <div className="ReactTable">
          <div className="rt-table" role="grid">
            <div className="rt-thead -header" style={{minWidth: '800px'}}>
              <div className="rt-tr" role="row">
                <div className="rt-th -cursor-pointer" role="columnheader" tabIndex={-1} style={{flex: '100 0 auto', width: '150px', textAlign: 'left', paddingLeft: '24px'}}>
                  <div>Country</div>
                </div>
                <div className="rt-th -cursor-pointer" role="columnheader" tabIndex={-1} style={{flex: '100 0 auto', width: '100px'}}>
                  <div>Tax Rate</div>
                </div>
                <div className="rt-th -cursor-pointer" role="columnheader" tabIndex={-1} style={{flex: '100 0 auto', width: '100px'}}>
                  <div>Monthly Expenses</div>
                </div>
                <div className="rt-th -cursor-pointer" role="columnheader" tabIndex={-1} style={{flex: '100 0 auto', width: '100px'}}>
                  <div>Avg. Developer Wage</div>
                </div>
                <div className="rt-th -cursor-pointer" role="columnheader" tabIndex={-1} style={{flex: '100 0 auto', width: '100px'}}>
                  <div>Avg. Temperature</div>
                </div>
                <div className="rt-th -cursor-pointer" role="columnheader" tabIndex={-1} style={{flex: '100 0 auto', width: '100px'}}>
                  <div>Employees</div>
                </div>
                <div className="rt-th -cursor-pointer" role="columnheader" tabIndex={-1} style={{flex: '100 0 auto', width: '100px'}}>
                  <div>2019 VC Funding</div>
                </div>
              </div>
            </div>
            <div className="rt-tbody" style={{minWidth: '800px'}}>
              <AniLink fade to="/ecosystems/estonia" className="country-link" duration={0.4}>
                <div className="rt-tr-group" role="rowgroup">
                    <div className="rt-tr -even" role="row">
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '150px'}}>🇪🇪  Estonia</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>0%</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$1,200</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$1,800</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>5.2 °C</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>5,235</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>€ 296M</div>
                    </div>
                </div>
                </AniLink>

                <AniLink fade to="/ecosystems/lithuania" className="country-link" duration={0.4}>
                <div className="rt-tr-group" role="rowgroup">
                    <div className="rt-tr -even" role="row">
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '150px'}}>🇱🇹  Lithuania</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>15%</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$1,100</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>$1,600</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>6.7 °C</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>8,500+</div>
                    <div className="rt-td" role="gridcell" style={{flex: '100 0 auto', width: '100px'}}>€ 171M</div>
                    </div>
                </div>
                </AniLink>
            </div>
          </div>
        </div>
      );}

  export default EcosystemTable
