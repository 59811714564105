import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const MobileEcosystems = ({ children }) => {
  return (
      <div className="mobile-list">
            <AniLink fade to="/ecosystems/estonia" duration={0.4}>
            <div className="item-container">
            <div className="item-header">
            <div className="country-icon-container">
          <span role="img" aria-label="Estonia">🇪🇪</span>
          </div>        
            Estonia
                <div className="item-arrow">
                →
                </div>
            </div>
            </div>
            </AniLink>

            <AniLink fade to="/ecosystems/lithuania" duration={0.4}>
            <div className="item-container">
            <div className="item-header">
            <div className="country-icon-container">
          <span role="img" aria-label="Lithuania">🇱🇹</span>
          </div>        
            Lithuania
                <div className="item-arrow">
                →
                </div>
            </div>
            </div>
            </AniLink>
        </div>
  )}
  
  export default MobileEcosystems
